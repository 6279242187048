import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css'
import { navigate } from 'gatsby';

const TermsOfService = () => {
    useEffect(() => {
        navigate('https://www.notion.so/Terms-of-Service-3c0dc0cc267945a7ad41d7d08d48cbaf')
    }, [])
    return(<div />)
}

export default TermsOfService;
